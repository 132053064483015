import React , { useState , useEffect} from 'react';
import logo from "../../assets/images/logo.png";
import { Helmet } from 'react-helmet';
import font from "../../assets/fonts/user.svg";
import { AuthChild } from '../auth';
import { useNavigate, Link } from 'react-router-dom';



function Header() {
  AuthChild.isAuthenticated()
  const navigate = useNavigate();
  const [page, setPage] = useState('');

  function logout(e) {
    e.preventDefault();
    localStorage.removeItem('admintoken');
    localStorage.removeItem('superadmintoken')
    navigate('/')
   
}

useEffect(()=>{
  var url = window.location.href.split('/');
  var split = url[url.length -1];
  setPage(split);
});

  return (
    <div>
      <header class="header">
        <div class="header__content">
          <a href="/Home" class="header__logo">
            <img src={logo} />
          </a>

          <button class="header__btn" type="button">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </header>

      <div class="sidebar">
        <a href="/" class="sidebar__logo">
          <img src={logo} />
        </a>

        <div class="sidebar__user">
          <div class="sidebar__user-img">
            <img src={font} alt />
          </div>

          <div class="sidebar__user-title">
            <span>Admin</span>
            <p>John Doe</p>
          </div>

          <button class="sidebar__user-btn" type="button" onClick={(e) => logout(e)}>
            <i class="icon ion-ios-log-out"></i>
          </button>
        </div>

        <div class="sidebar__nav-wrap">
          {AuthChild.isRole ?
            <>
             
              <li class="sidebar__nav-item">
                <a href="/theatrelist" class="sidebar__nav-link"><i class="icon ion-ios-contacts"></i> <span>Theatre List</span></a>
              </li>
              <li class="sidebar__nav-item">
                <a href="/movielist" class="sidebar__nav-link"><i class="icon ion-ios-contacts"></i> <span>Movie List</span></a>
              </li>
            </> :
            <>

              <ul class="sidebar__nav">
                <li class="sidebar__nav-item">
                  <a href="/" class={`sidebar__nav-link ${page==="" && "sidebar__nav-link--active"}`}><i class="icon ion-ios-keypad"></i> <span>Dashboard</span></a>
                </li>

                <li class="sidebar__nav-item">
                  <a href="/catelog" class={`sidebar__nav-link ${page==="catelog" && "sidebar__nav-link--active"}`}><i class="icon ion-ios-film"></i> <span>Catalog</span></a>
                </li>

                <li class="sidebar__nav-item">
                  <a class={`sidebar__nav-link ${page==="edituser" || page==="additem" || page==="approvedTheatre" && "sidebar__nav-link--active"}`} data-toggle="collapse" href="#collapseMenu" role="button" aria-expanded="false" aria-controls="collapseMenu"><i class="icon ion-ios-copy"></i> <span>Pages</span> <i class="icon ion-md-arrow-dropdown"></i></a>

                  <ul class={`${page=="edituser" || page==="additem" || page==="approvedTheatre" ? "" : "collapse"} sidebar__menu`} id="collapseMenu">
                    <li className={`${page==="edituser" || page==="additem" || page==="approvedTheatre" && "sidebar__nav-link--active"}`}><a href="/additem">Add item</a></li>
                    <li className={`${page==="edituser" || page==="additem" || page==="approvedTheatre" && "sidebar__nav-link--active"}`}><a href="/edituser">Edit user</a></li>
                    {/* <li><a href="/signin">Sign in</a></li> */}
                    <li className={`${page==="edituser" || page==="additem" || page==="approvedTheatre" && "sidebar__nav-link--active"}`}><a href="/approvedTheatre">Approved Theatre List</a></li>
                    {/* <li><a href="/rejectedTheatreList">Rejected Theatre List</a></li> */}
                    {/* <li><a href="/approvedMovieList">Approved Movie List</a></li> */}
                    {/* <li><a href="/rejectedMovieList">Rejected Movie List</a></li> */}
                    {/* <li><a href="/getAllTheatreList">All Theatres</a></li> */}
                    {/* <li><a href="/getMovieDetails">Movie Details</a></li> */}


                    {/* <li><a href="forgot.html">Forgot password</a></li> */}
                    {/* <li><a href="404.html">404 page</a></li> */}
                  </ul>
                </li>

                <li class="sidebar__nav-item">
                  <a href="/users" class="sidebar__nav-link"><i class="icon ion-ios-contacts"></i> <span>Users</span></a>
                </li>
                <li class="sidebar__nav-item">
                  <a href="/getadminrequest" class="sidebar__nav-link"><i class="icon ion-ios-contacts"></i> <span>Get Admin Request</span></a>
                </li>
{/* 
                <li class="sidebar__nav-item">
                  <a href="comments.html" class="sidebar__nav-link"><i class="icon ion-ios-chatbubbles"></i> <span>Comments</span></a>
                </li>

                <li class="sidebar__nav-item">
                  <a href="reviews.html" class="sidebar__nav-link"><i class="icon ion-ios-star-half"></i> <span>Reviews</span></a>
                </li> */}

                <li class="sidebar__nav-item">
                  {/* <a href="../main/index.html" class="sidebar__nav-link"><i class="icon ion-ios-arrow-round-back"></i> <span>Back to Block your Movie</span></a> */}
                </li>
              </ul>
            </>

          }
        </div>

        <div class="sidebar__copyright">© blockyourmovie, 2022. <br />Create by <a href="#" target="_blank">Pori</a></div>
      </div>

      <Helmet>

        <script src="../../assets/js/jquery-3.5.1.min.js"></script>
        <script src="../../assets/js/bootstrap.bundle.min.js"></script>
        <script src="../../assets/js/jquery.magnific-popup.min.js"></script>
        <script src="../../assets/js/jquery.mousewheel.min.js"></script>
        <script src="../../assets/js/jquery.mCustomScrollbar.min.js"></script>
        <script src="../../assets/js/select2.min.js"></script>
        <script src="../../assets/js/admin.js"></script>
      </Helmet>
    </div>
  )
}

export default Header