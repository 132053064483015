import React from 'react';
import font from "../../assets/fonts/user.svg"
import Header from '../Header/Header';

function EditUser() {
    return (
        <div>
            <Header />
            <main class="main">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="main__title">
                                <h2>Edit user</h2>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="profile__content">
                                <div class="profile__user">
                                    <div class="profile__avatar">
                                        <img src={font} alt="" />
                                    </div>
                                    <div class="profile__meta profile__meta--green">
                                        <h3>John Doe <span>(Approved)</span></h3>
                                        <span>Block your Movie ID: 23562</span>
                                    </div>
                                </div>

                                <ul class="nav nav-tabs profile__tabs" id="profile__tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Profile</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Comments</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false">Reviews</a>
                                    </li>
                                </ul>

                                <div class="profile__mobile-tabs" id="profile__mobile-tabs">
                                    <div class="profile__mobile-tabs-btn dropdown-toggle" role="navigation" id="mobile-tabs" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <input type="button" value="Profile" />
                                        <span></span>
                                    </div>

                                    <div class="profile__mobile-tabs-menu dropdown-menu" aria-labelledby="mobile-tabs">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item"><a class="nav-link active" id="1-tab" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Profile</a></li>

                                            <li class="nav-item"><a class="nav-link" id="2-tab" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Comments</a></li>

                                            <li class="nav-item"><a class="nav-link" id="3-tab" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false">Reviews</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="profile__actions">
                                    <a href="#modal-status3" class="profile__action profile__action--banned open-modal"><i class="icon ion-ios-lock"></i></a>
                                    <a href="#modal-delete3" class="profile__action profile__action--delete open-modal"><i class="icon ion-ios-trash"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="1-tab">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <form action="#" class="form form--profile">
                                                <div class="row row--form">
                                                    <div class="col-12">
                                                        <h4 class="form__title">Profile details</h4>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="username">Username</label>
                                                            <input id="username" type="text" name="username" class="form__input" placeholder="User 123" />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="email">Email</label>
                                                            <input id="email" type="text" name="email" class="form__input" placeholder="email@email.com" />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="firstname">First Name</label>
                                                            <input id="firstname" type="text" name="firstname" class="form__input" placeholder="John" />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="lastname">Last Name</label>
                                                            <input id="lastname" type="text" name="lastname" class="form__input" placeholder="Doe" />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="subscription">Subscription</label>
                                                            <select class="js-example-basic-single" id="subscription">
                                                                <option value="Basic">Basic</option>
                                                                <option value="Premium">Premium</option>
                                                                <option value="Cinematic">Cinematic</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="rights">Rights</label>
                                                            <select class="js-example-basic-single" id="rights">
                                                                <option value="User">User</option>
                                                                <option value="Moderator">Moderator</option>
                                                                <option value="Admin">Admin</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-12">
                                                        <button class="form__btn" type="button">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <form action="#" class="form form--profile">
                                                <div class="row row--form">
                                                    <div class="col-12">
                                                        <h4 class="form__title">Change password</h4>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="oldpass">Old Password</label>
                                                            <input id="oldpass" type="password" name="oldpass" class="form__input" />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="newpass">New Password</label>
                                                            <input id="newpass" type="password" name="newpass" class="form__input" />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                                                        <div class="form__group">
                                                            <label class="form__label" for="confirmpass">Confirm New Password</label>
                                                            <input id="confirmpass" type="password" name="confirmpass" class="form__input" />
                                                        </div>
                                                    </div>

                                                    <div class="col-12">
                                                        <button class="form__btn" type="button">Change</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="2-tab">
                                <div class="col-12">
                                    <div class="main__table-wrap">
                                        <table class="main__table">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>ITEM</th>
                                                    <th>AUTHOR</th>
                                                    <th>TEXT</th>
                                                    <th>LIKE / DISLIKE</th>
                                                    <th>CRAETED DATE</th>
                                                    <th>ACTIONS</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">23</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">I Dream in Another Language</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">12 / 7</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">24</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Benched</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">67 / 22</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">25</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Whitney</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">44 / 5</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">26</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Blindspotting</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">20 / 6</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">27</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">I Dream in Another Language</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">8 / 132</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">28</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Benched</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">6 / 1</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">29</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Whitney</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">10 / 0</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">30</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Blindspotting</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">13 / 14</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">31</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">I Dream in Another Language</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">12 / 7</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">32</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Benched</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">67 / 22</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="paginator-wrap">
                                        <span>10 from 23</span>

                                        <ul class="paginator">
                                            <li class="paginator__item paginator__item--prev">
                                                <a href="#"><i class="icon ion-ios-arrow-back"></i></a>
                                            </li>
                                            <li class="paginator__item"><a href="#">1</a></li>
                                            <li class="paginator__item paginator__item--active"><a href="#">2</a></li>
                                            <li class="paginator__item"><a href="#">3</a></li>
                                            <li class="paginator__item"><a href="#">4</a></li>
                                            <li class="paginator__item paginator__item--next">
                                                <a href="#"><i class="icon ion-ios-arrow-forward"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="3-tab">
                                <div class="col-12">
                                    <div class="main__table-wrap">
                                        <table class="main__table">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>ITEM</th>
                                                    <th>AUTHOR</th>
                                                    <th>TEXT</th>
                                                    <th>RATING</th>
                                                    <th>LIKE / DISLIKE</th>
                                                    <th>CRAETED DATE</th>
                                                    <th>ACTIONS</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">23</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">I Dream in Another Language</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 7.9</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">12 / 7</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">24</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Benched</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 8.6</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">67 / 22</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">25</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Whitney</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 6.0</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">44 / 5</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">26</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Blindspotting</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 9.1</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">20 / 6</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">27</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">I Dream in Another Language</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 5.5</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">8 / 132</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">28</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Benched</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 7.0</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">6 / 1</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">29</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Whitney</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 9.0</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">10 / 0</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">30</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Blindspotting</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 6.2</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">13 / 14</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">31</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">I Dream in Another Language</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 7.9</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">12 / 7</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="main__table-text">32</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text"><a href="#">Benched</a></div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">John Doe</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">Lorem Ipsum is simply dummy text...</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text main__table-text--rate"><i class="icon ion-ios-star"></i> 8.6</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">67 / 22</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-text">24 Oct 2021</div>
                                                    </td>
                                                    <td>
                                                        <div class="main__table-btns">
                                                            <a href="#modal-view2" class="main__table-btn main__table-btn--view open-modal">
                                                                <i class="icon ion-ios-eye"></i>
                                                            </a>
                                                            <a href="#modal-delete2" class="main__table-btn main__table-btn--delete open-modal">
                                                                <i class="icon ion-ios-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="paginator-wrap">
                                        <span>10 from 32</span>

                                        <ul class="paginator">
                                            <li class="paginator__item paginator__item--prev">
                                                <a href="#"><i class="icon ion-ios-arrow-back"></i></a>
                                            </li>
                                            <li class="paginator__item"><a href="#">1</a></li>
                                            <li class="paginator__item paginator__item--active"><a href="#">2</a></li>
                                            <li class="paginator__item"><a href="#">3</a></li>
                                            <li class="paginator__item"><a href="#">4</a></li>
                                            <li class="paginator__item paginator__item--next">
                                                <a href="#"><i class="icon ion-ios-arrow-forward"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default EditUser