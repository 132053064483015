import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../../Header/Header';
import common_url from '../../../http_common';

function AddScreens() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [screenName, setScreenName] = useState('');
    const [width, setwidth] = useState('');
    const [height, setheight] = useState('');
    const [className, setClassname] = useState('');
    const [amount, setAmount] = useState('');
    const [classVal, setclassVal] = useState([]);
    const [rowName, setRowName] = useState('');
    const [row, setRow] = useState([]);
    const [seat, setSeat] = useState([]);
    const [name, setName] = useState('');
    const [status, setStatus] = useState(0);

    const Id = state.theatreId
    console.log(Id, 'statescrens')

    const theatreName = state.theatreName


    async function Addscreen(e) {
        e.preventDefault()
        const token = localStorage.getItem('admintoken');

        let screenSize = { width, height }
        let screen = { screenName, screenSize, classVal }
        let payload = { theatreName, screen }
        console.log(payload)
        e.preventDefault();
        let resp = await fetch(common_url+'theatre/addtheatre/' + Id,
            {
                method: 'POST',
                headers: {
                    'Authorization': token,

                    'Content-type': 'application/JSON'
                },
                body: JSON.stringify(payload)
            })
        const status = resp.status

        resp = await resp.json()
        // console.log(resp.message._id)
        // const id = resp.message._id
        if (status == 200 || status == 204) {
            // navigate("/addscreens", { state: id });
            //   setStatusmode(true)
        }
    }
    function remove(e, className) {
        const arr1 = classVal.filter((item) => item.className !== className);
        setclassVal(arr1);
    }
    function addClass() {
        setclassVal([...classVal, { className, amount, row }])
        console.log(classVal, 'classval')
    }
    function addRow() {
        setRow([...row, { rowName,seat }])
        console.log(row, 'classrow')
    }
    function addSeat() {
        setSeat([...seat, { name, status }])
        console.log(seat, 'classSeat')
    }
    return (
        <div>
            <Header />
            <main class="main">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="main__title">
                                <h2>Add Screens</h2>
                            </div>
                        </div>
                        <div class="col-12">
                            <form class="form" onSubmit={(e) => Addscreen(e)}>
                                <div class="row row--form">
                                    <div class="col-12 col-md-7 form__content">
                                        <div class="row row--form">
                                            <div class="col-12">
                                                <input type="text" class="form__input" placeholder="ScreenName" onChange={(e) => setScreenName(e.target.value)} />
                                            </div>

                                            <div class="col-12 col-sm-6 col-lg-6">
                                                <input type="text" class="form__input" placeholder="width" onChange={(e) => setwidth(e.target.value)} />
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-6">
                                                <input type="text" class="form__input" placeholder="Height" onChange={(e) => setheight(e.target.value)} />
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="class name" onChange={(e) => setClassname(e.target.value)} />
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="amount" onChange={(e) => setAmount(e.target.value)} />
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <a className="form__btn" style={{
                                                    background: 'white',
                                                    height: '46px',
                                                    width: '140px',
                                                    borderRadius: '6px',
                                                    fontSize: '14px',
                                                    border: " 2px solid #f9ab00",
                                                    marginTop: ' 0px'
                                                }} onClick={addClass}>Add class</a><br />
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-3">
                                                {classVal.length > 0 && classVal.map((list) =>
                                                    <>
                                                        <div class="close-msg-grp">
                                                            <span>
                                                                <ion-icon name="close-outline" onClick={(e) => remove(e, list.className)} style={{ color: 'white' }}></ion-icon>

                                                            </span>
                                                            <span>
                                                                <p className="" style={{ color: 'white' }}>{list.className}</p>

                                                            </span>
                                                        </div>
                                                       
                                                    </>)}
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="RowName" onChange={(e) => setRowName(e.target.value)} />
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <a className="form__btn" style={{
                                                   background: 'white',
                                                   height: '46px',
                                                   width: '140px',
                                                   borderRadius: '6px',
                                                   fontSize: '14px',
                                                   border: " 2px solid #f9ab00",
                                                   marginTop: ' 0px'
                                                }} onClick={addRow}>Add Row</a><br />
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-3">
                                                {row.length > 0 && row.map((list) =>
                                                    <>
                                                        <div class="close-msg-grp">
                                                        
                                                        <span>
                                                            <ion-icon name="close-outline" onClick={(e) => remove(e, list.rowName)} style={{ color: 'white' }}></ion-icon>

                                                        </span>
                                                        <span>
                                                            <p className="" style={{ color: 'white' }}>{list.rowName}</p>

                                                        </span>
                                                        </div>
                                                    </>)}
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-3">
                                                
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="name" onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            {/* <div class="col-lg-6 col-md-6 col-sm-12 col-12 p-2">
                                                <span class="fw-light">Status</span>
                                                <div>
                                                    <label class="switch btn-color-mode-switch mt-2">
                                                        <input type="checkbox" name="color_mode" id="color_mode" onClick={() => setStatus(0)} />
                                                        <label for="color_mode" data-on="Available" data-off="NotAvailable"
                                                            class="btn-color-mode-switch-inner"></label>
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className="col-12 col-sm-6 col-lg-3">
                                                <a className="form__btn" style={{
                                                   background: 'white',
                                                   height: '46px',
                                                   width: '140px',
                                                   borderRadius: '6px',
                                                   fontSize: '14px',
                                                   border: " 2px solid #f9ab00",
                                                   marginTop: ' 0px'
                                                }} onClick={addSeat}>Add seat</a><br />
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-3">
                                                {seat.length > 0 && seat.map((list) =>
                                                    <>
                                                        <div class="close-msg-grp">
                                                         <span>
                                                            <ion-icon name="close-outline" onClick={(e) => remove(e, list.name)} style={{ color: 'white' }}></ion-icon>

                                                        </span>
                                                        <span>
                                                            <p className="" style={{ color: 'white' }}>{list.name}</p>

                                                        </span>
                                                        </div>
                                                       
                                                    </>)}
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row row--form">

                                            <div class="col-12">
                                                <button class="form__btn">publish</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </main >
        </div >
    )
}

export default AddScreens