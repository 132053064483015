export const AuthChild = {
    isLogged : false,
  isRole:false,
    isAuthenticated()
   {
       const admintoken=localStorage.getItem('admintoken');
      const superadmintoken=localStorage.getItem('superadmintoken')
           if((admintoken!==undefined && admintoken !==null)||(superadmintoken!==undefined && superadmintoken !==null))
           {
               this.isLogged =true;
               if(admintoken!==undefined && admintoken !==null){
                this.isRole=true; 
           
               }
            //    else{
            //        this.logged=false;
            //        this.isRole=false; 
    
            //    }
           
           }
          
   }
   }