import React, { useState, useEffect } from 'react';
import font from "./../../assets/fonts/user.svg";
import Header from './../Header/Header';
import axios from 'axios';
import swal from 'sweetalert';
import common_url from '../../http_common';

function GetAdminRequest() {
    const [userList, setuserList] = useState([]);
    const[currentPage,setCurrentPage] = useState(1);

    const limit = 10;

    useEffect(() => {
        UserList(currentPage);
    }, [limit,currentPage]);
  
  
    async function UserList(currentPage) {
        const data = await axios({
            'method': 'GET',
            'url':  `${common_url}admin/getAllAdminList?page=${currentPage}&limit=${limit}`,
            'headers': {
                'Content-Type': 'application/json',
            },
        })
        console.log(data.data.message.result,'data')
           let result = data.data.message.result;
        console.log(result)
        let data1=result.filter((x)=>x.status==0)
        console.log(data1,'data1pad')
        setuserList(data1)

        // setItems(data1);

        // console.log(currentPage);
        // const data = await res.json()
        // const total =  data.data.count;
        // setpageCount(Math.ceil(total / limit));
        // let result = data.data.message.result;
        // console.log(result)
        // let data1=result.filter((x)=>x.status==0)
        // console.log(data1,'data1pad')
        // setItems(data1);
    };
    async function Reject(e, list) {
        e.preventDefault();
        const token = localStorage.getItem('superadmintoken');

        let payload = { role: "reject" }
        let data = await fetch(`https://node.firehrm.com/BYM/admin/TheatreSelection/` + list._id, {
            'method': 'POST',
            'headers': {
                'Authorization': token,

                'Content-Type': 'application/json',

            },
            'body': JSON.stringify(payload)
        }) 
         data = await data.json()
         console.log(data,'data')
                if (data.data1.status === 'false') {
                    swal({
                        title: "success!",
                        text: data.message,
                        icon: "success",
                        button: "ok!",
                    });
                    window.location.reload(false)
                }
              
    }
    async function Accept(e, list) {
        e.preventDefault();
        const token = localStorage.getItem('superadmintoken');

        let payload = { role: "accept" }
        let data = await fetch(`https://node.firehrm.com/BYM/admin/TheatreSelection/` + list._id, {
            'method': 'POST',
            'headers': {
                'Authorization': token,

                'Content-Type': 'application/json',
            }, 'body': JSON.stringify(payload)

        })
        data = await data.json()
         console.log(data,'data')
                if (data.data1.status === 'true') {
                    swal({
                        title: "success!",
                        text: data.message,
                        icon: "success",
                        button: "ok!",
                    });
                    window.location.reload(false)
                }
    }
    return (
        <div>
            <Header />
            <main class="main">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="main__title">
                                <h2>Users</h2>

                                <span class="main__title-stat">3,702 Total</span>

                                <div class="main__title-wrap">
                                    <div class="filter" id="filter__sort">
                                        <span class="filter__item-label">Sort by:</span>

                                        <div class="filter__item-btn dropdown-toggle" role="navigation" id="filter-sort" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <input type="button" value="Date created" />
                                            <span></span>
                                        </div>

                                        <ul class="filter__item-menu dropdown-menu scrollbar-dropdown" aria-labelledby="filter-sort">
                                            <li>Date created</li>
                                            <li>Pricing plan</li>
                                            <li>Status</li>
                                        </ul>
                                    </div>

                                    <form action="#" class="main__title-form">
                                        <input type="text" placeholder="Find user.." />
                                        <button type="button">
                                            <i class="icon ion-ios-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="main__table-wrap">
                                <table class="main__table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>BASIC INFO</th>
                                            <th>USERNAME</th>
                                            <th>Theatre Name</th>
                                            <th>Theatre Address</th>
                                            <th>Location</th>
                                            <th>STATUS</th>
                                            {/* <th>CRAETED DATE</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userList?.map(list =>
                                            <tr>
                                                <td>
                                                    <div class="main__table-text">1</div>
                                                </td>
                                                <td>
                                                    <div class="main__user">
                                                        <div class="main__avatar">
                                                            <img src={font} alt="" />
                                                        </div>
                                                        <div class="main__meta">
                                                            <h3>{list.name}</h3>
                                                            <span>{list.email}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="main__table-text">{list.name}</div>
                                                </td>
                                                <td>
                                                    <div class="main__table-text">{list.theatreName}</div>
                                                </td>
                                                <td>
                                                    <div class="main__table-text">{list.theatreAddress}</div>
                                                </td>
                                                <td>
                                                    <div class="main__table-text">{list.location}</div>
                                                </td>


                                                <td>
                                                    {/* <div class="main__table-text">{list.createdAt}</div> */}
                                                    <button class="form__btn" type="button" onClick={(e) => Accept(e, list)}>Accept</button>
                                                    <button class="form__btn" type="button" onClick={(e) => Reject(e, list)}>Reject</button>

                                                </td>

                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="paginator-wrap">
                                <span>10 from 3 702</span>

                                <ul class="paginator">
                                    <li class="paginator__item paginator__item--prev">
                                        <a href="#"><i class="icon ion-ios-arrow-back"></i></a>
                                    </li>
                                    <li class="paginator__item"><a href="#">1</a></li>
                                    <li class="paginator__item paginator__item--active"><a href="#">2</a></li>
                                    <li class="paginator__item"><a href="#">3</a></li>
                                    <li class="paginator__item"><a href="#">4</a></li>
                                    <li class="paginator__item paginator__item--next">
                                        <a href="#"><i class="icon ion-ios-arrow-forward"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default GetAdminRequest