import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../Header/Header';
import common_url from '../../http_common';

function AddMovie() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [movieName, setMovieName] = useState('');
    const [duration, setDuration] = useState('');
    const [releaseDate, setReleaseDate] = useState('');
    const [about, setAbout] = useState('');
    const [language, setLanguage] = useState([]);
    const [singleLanguage, setSingleLanguage] = useState([]);
    const [lang, setLang] = useState('');
    const [typeOfMovie, setTypeOfMovie] = useState([]);
    const [singleTypeOfMovie, setSingleTypeOfMovie] = useState([]);
    const [dimension, setDimension] = useState([]);
    const [singleDimension, setSingleDimension] = useState([]);
    const [cast, setCast] = useState([]);
    const [crew, setCrew] = useState([]);
    const [addPhotos, setAddPhotos] = useState([]);
    const [movieImage, setMovieImage] = useState([]);




    // console.log(state, 'statescrens')


    async function AddMovie(e) {
        e.preventDefault()
        const token = localStorage.getItem('admintoken');
        // let screenSize = { width, height }
        // let screen = { screenName, screenSize, classVal }
        let payload = { movieName, duration, releaseDate, about, language, dimension, typeOfMovie }
        console.log(payload)
        e.preventDefault();
        let resp = await fetch(common_url+'movie/addMovie',
            {
                method: 'POST',
                headers: {
                    'Authorization': token,

                    'Content-type': 'application/JSON'
                },
                body: JSON.stringify(payload)
            })
        const status = resp.status
        resp = await resp.json()
        if (status == 200 || status == 204) {

        }
    }
    async function upload(e) {
        e.preventDefault();
        const formData3 = new FormData()
        console.log(movieImage,'image');
        formData3.append('image', movieImage)
        await fetch(common_url+'theatre/image', {
            method: 'POST',
            body: formData3
        })
            .then(response => {
                console.log(response);
                return response.json()
            }).then(resp => {
                console.log(resp);
                // let object = { movieImage: resp.message.actorImage, name: resp.message.name, designation: resp.message.designation }
                // setMovieImage(resp.message.image)
                console.log(movieImage, 'movieimage')
                // ref1.current.value = "";
            })
    }
    function Add(e, type) {
        e.preventDefault()
        switch (type) {
            case "language":
                console.log(singleLanguage)
                const obj = singleLanguage
                console.log(obj)
                setLanguage([...language, singleLanguage])
                console.log(language)
                break;
            case "dimension":
                setDimension([...dimension, singleDimension])

                console.log(dimension)
                break;
            case "typeofmovie":
                setTypeOfMovie([...typeOfMovie, singleTypeOfMovie])
                console.log(typeOfMovie)
                break;
            default:
                break;
        }
    }
    function remove(e, list, type) {
        switch (type) {
            case "language":
                let arr = language.filter((item) => item !== list)
                setLanguage(arr)
                console.log(language)
                break;
            case "dimension":
                let arr1 = dimension.filter((item) => item !== list)
                setDimension(arr1)
                console.log(dimension)
                break;
            case "typeofmovie":
                let arr2 = typeOfMovie.filter((item) => item !== list)
                setTypeOfMovie(arr2)
                console.log(typeOfMovie)
                break;
            case "crew":
                let arr3 = crew.filter((item) => item !== list)
                setCrew(arr3)

                console.log(crew)
                break;
            case "cast":
                let arr4 = cast.filter((item) => item !== list)
                setCast(arr4)

                console.log(arr4)
                console.log(cast, "cast1")
                break;
            default:
                break;
        }
    }
    return (
        <div>
            <Header />
            <main class="main">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="main__title">
                                <h2>Add Movie</h2>
                            </div>
                        </div>

                        <div class="col-12">
                            <form onSubmit={(e) => AddMovie(e)} class="form">
                                <div class="row row--form">
                                    <div class="col-12 col-md-5 form__cover">
                                        <div class="row row--form">
                                            <div class="col-12 col-sm-6 col-md-12">
                                                <div class="form__img">
                                                    <label for="form__img-upload">Upload cover (270 x 400)</label>
                                                    <input id="form__img-upload" name="form__img-upload" type="file" accept=".png, .jpg, .jpeg"    onChange={(e) => setMovieImage(e.target.files[0])} />
                                                    <img id="form__img" src={movieImage} alt=" " />
                                                    <button   style={{ color: 'white' }} onClick={(e) => { upload(e) }}>Upload</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-7 form__content">
                                        <div class="row row--form">
                                            <div class="col-12">
                                                <input type="text" class="form__input" placeholder="MovieName" onChange={(e) => setMovieName(e.target.value)} />
                                            </div>

                                            <div class="col-12">
                                                <textarea id="text" name="text" class="form__textarea" placeholder="About" onChange={(e) => setAbout(e.target.value)}></textarea>
                                            </div>

                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="Duration" onChange={(e) => setDuration(e.target.value)} />
                                            </div>

                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="ReleaseDate" onChange={(e) => setReleaseDate(e.target.value)} />
                                            </div>

                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="Langurage" onChange={(e) => setSingleLanguage(e.target.value)} />
                                                <div className="col-12 col-sm-6 col-lg-12">
                                                    <a class="" style={{
                                                        background: 'white',
                                                        height: '46px',
                                                        width: '140px',
                                                        borderRadius: '6px',
                                                        fontSize: '14px',
                                                        border: " 2px solid #f9ab00",
                                                        padding: "5px",
                                                        display: "ruby-base",
                                                        marginBottom: "10px",
                                                    }} onClick={(e) => Add(e, "language")}>Add languge</a><br />
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-12">

                                                    {language.length > 0 && language.map((list) =>
                                                        <>  
                                                            <div class="close-msg-grp add-movie-fun-margin">
                                                                <div class="close-icn">
                                                                     <ion-icon name="close-outline" onClick={(e) => remove(e, list, "language")} style={{ color: 'white' }}></ion-icon>
                                                                </div>
                                                                <div class="">
                                                                      <p className="btn btn-danger ms-1  relative" style={{ color: 'white' }}>{list}</p>
                                                                </div>
                                                            </div>

                                                        </>
                                                        )}
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="Dimension" onChange={(e) => setSingleDimension(e.target.value)} />
                                                <div className="col-12 col-sm-6 col-lg-12">
                                                    <a style={{
                                                        background: 'white',
                                                        height: '46px',
                                                        width: '140px',
                                                        borderRadius: '6px',
                                                        fontSize: '14px',
                                                        padding: "5px",
                                                        border: " 2px solid #f9ab00",
                                                        display: "ruby-base",
                                                        marginBottom: "10px",
                                                    }} onClick={(e) => Add(e, "dimension")}>Add dimension</a><br />
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-12">

                                                    {dimension.length > 0 && dimension.map((list) =>
                                                        <>
                                                            <div class="close-msg-grp add-movie-fun-margin">
                                                               <div class="close-icn">
                                                                   <ion-icon name="close-outline" onClick={(e) => remove(e, list, "dimension")} style={{ color: 'white' }}></ion-icon>
                                                                </div>
                                                                <div class="">
                                                                     <p className="btn btn-danger ms-1  relative" style={{ color: 'white' }} >{list}</p>
                                                                </div>
                                                                
                                                            </div>


                                                        </>)}
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="TypeOfMovie" onChange={(e) => setSingleTypeOfMovie(e.target.value)} />
                                                <div className="col-12 col-sm-6 col-lg-12">
                                                    <a style={{
                                                        background: 'white',
                                                        height: '46px',
                                                        width: '140px',
                                                        borderRadius: '6px',
                                                        fontSize: '14px',
                                                        padding: "5px",
                                                        border: " 2px solid #f9ab00",
                                                        display: "ruby-base",
                                                        marginBottom: "10px",
                                                    }} onClick={(e) => Add(e, "typeofmovie")}>Add typeofMovie</a><br />
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-12">

                                                    {typeOfMovie.length > 0 && typeOfMovie.map((list) =>
                                                        <>
                                                            <div class="close-msg-grp add-movie-fun-margin">
                                                            <div class="close-icn">
                                                                     <ion-icon name="close-outline" onClick={(e) => remove(e, list, "typeofmovie")} style={{ color: 'white' }}></ion-icon>
                                                                </div>
                                                                <div class="">
                                                                  <p className="btn btn-danger ms-1 relative" style={{ color: 'white' }}>{list}</p>
                                                                  </div>
                                                                
                                                            </div>

                                                        </>)}
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6 col-lg-3">
                                                <input type="text" class="form__input" placeholder="Age" onChange={(e) => setAbout(e.target.value)} />
                                            </div>

                                            <div class="col-12 col-lg-6">
                                                <input type="text" class="form__input" placeholder="Running timed in minutes" />

                                            </div>

                                            <div class="col-12 col-lg-6">
                                                <input type="text" class="form__input" placeholder="Running timed in minutes" />

                                            </div>

                                            <div class="col-12">
                                                <div class="form__gallery form__input">
                                                    <label id="gallery1" for="form__gallery-upload">Upload photos</label>
                                                    <input data-name="#gallery1" id="form__gallery-upload" name="gallery" class="form__gallery-upload" type="file" accept=".png, .jpg, .jpeg" multiple />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <ul class="form__radio">
                                            <li>
                                                <span>Item type:</span>
                                            </li>

                                        </ul>
                                    </div>

                                    <div class="col-12">
                                        <div class="row row--form">
                                            <div class="col-12">
                                                <div class="form__video form__input">
                                                    <label id="movie1" for="form__video-upload">Upload video</label>
                                                    <input data-name="#movie1" id="form__video-upload" name="movie" class="form__video-upload" type="file" accept="video/mp4,video/x-m4v,video/*" />
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <input type="text" class="form__input" placeholder="Or add a link" />
                                            </div>

                                            <div class="col-12">
                                                <button class="form__btn">publish</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div >
    )
}

export default AddMovie