import font from "./../../assets/fonts/user.svg";
import Header from './../Header/Header';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import common_url from '../../http_common';

function ApprovedTheatreList() {
  const [theatreList, setTheatreList] = useState([]);

  useEffect(() => {
    TheatreList();
  }, []);
  const TheatreList = async () => {
    axios({
      'method': 'GET',
      'url': common_url+'admin/approvedTheatreList',
      'headers': {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        console.log(response.data.message);
        setTheatreList(response.data.data)
      })
      .then
      .catch((error) => {
        alert(error)
      }
      )
  }
  return (
    <div>
      <Header />
      <main class="main">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="main__title">
                <h2>Users</h2>

                <span class="main__title-stat">3,702 Total</span>

                <div class="main__title-wrap">
                  <div class="filter" id="filter__sort">
                    <span class="filter__item-label">Sort by:</span>

                    <div class="filter__item-btn dropdown-toggle" role="navigation" id="filter-sort" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <input type="button" value="Date created" />
                      <span></span>
                    </div>

                    <ul class="filter__item-menu dropdown-menu scrollbar-dropdown" aria-labelledby="filter-sort">
                      <li>Date created</li>
                      <li>Pricing plan</li>
                      <li>Status</li>
                    </ul>
                  </div>

                  <form action="#" class="main__title-form">
                    <input type="text" placeholder="Find user.." />
                    <button type="button">
                      <i class="icon ion-ios-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="main__table-wrap">
                <table class="main__table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>BASIC INFO</th>
                      <th>USERNAME</th>
                      <th>PRICING PLAN</th>
                      <th>COMMENTS</th>
                      <th>REVIEWS</th>
                      <th>STATUS</th>
                      <th>CRAETED DATE</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div class="main__table-text">23</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Premium</div>
                      </td>
                      <td>
                        <div class="main__table-text">13</div>
                      </td>
                      <td>
                        <div class="main__table-text">1</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--green">Approved</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">24</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Free</div>
                      </td>
                      <td>
                        <div class="main__table-text">1</div>
                      </td>
                      <td>
                        <div class="main__table-text">15</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--green">Approved</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">25</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Premium</div>
                      </td>
                      <td>
                        <div class="main__table-text">6</div>
                      </td>
                      <td>
                        <div class="main__table-text">6</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--green">Approved</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">26</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Free</div>
                      </td>
                      <td>
                        <div class="main__table-text">11</div>
                      </td>
                      <td>
                        <div class="main__table-text">15</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--red">Banned</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">27</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Basic</div>
                      </td>
                      <td>
                        <div class="main__table-text">0</div>
                      </td>
                      <td>
                        <div class="main__table-text">0</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--green">Approved</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">28</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Free</div>
                      </td>
                      <td>
                        <div class="main__table-text">2</div>
                      </td>
                      <td>
                        <div class="main__table-text">1</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--green">Approved</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">29</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Cinematic</div>
                      </td>
                      <td>
                        <div class="main__table-text">65</div>
                      </td>
                      <td>
                        <div class="main__table-text">0</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--green">Approved</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">30</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Premium</div>
                      </td>
                      <td>
                        <div class="main__table-text">0</div>
                      </td>
                      <td>
                        <div class="main__table-text">0</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--red">Banned</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">31</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Premium</div>
                      </td>
                      <td>
                        <div class="main__table-text">13</div>
                      </td>
                      <td>
                        <div class="main__table-text">1</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--green">Approved</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="main__table-text">32</div>
                      </td>
                      <td>
                        <div class="main__user">
                          <div class="main__avatar">
                            <img src={font} alt="" />
                          </div>
                          <div class="main__meta">
                            <h3>John Doe</h3>
                            <span>email@email.com</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="main__table-text">Username</div>
                      </td>
                      <td>
                        <div class="main__table-text">Free</div>
                      </td>
                      <td>
                        <div class="main__table-text">1</div>
                      </td>
                      <td>
                        <div class="main__table-text">15</div>
                      </td>
                      <td>
                        <div class="main__table-text main__table-text--red">Banned</div>
                      </td>
                      <td>
                        <div class="main__table-text">24 Oct 2021</div>
                      </td>
                      <td>
                        <div class="main__table-btns">
                          <a href="#modal-status" class="main__table-btn main__table-btn--banned open-modal">
                            <i class="icon ion-ios-lock"></i>
                          </a>
                          <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                            <i class="icon ion-ios-create"></i>
                          </a>
                          <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                            <i class="icon ion-ios-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="paginator-wrap">
                <span>10 from 3 702</span>

                <ul class="paginator">
                  <li class="paginator__item paginator__item--prev">
                    <a href="#"><i class="icon ion-ios-arrow-back"></i></a>
                  </li>
                  <li class="paginator__item"><a href="#">1</a></li>
                  <li class="paginator__item paginator__item--active"><a href="#">2</a></li>
                  <li class="paginator__item"><a href="#">3</a></li>
                  <li class="paginator__item"><a href="#">4</a></li>
                  <li class="paginator__item paginator__item--next">
                    <a href="#"><i class="icon ion-ios-arrow-forward"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ApprovedTheatreList