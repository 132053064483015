import font from "../../assets/fonts/user.svg";

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import common_url from '../../http_common';

function MovieList() {
    const [theatreList, setTheatreList] = useState([]);
    const nav = useNavigate();


    function AddScreens(e, x) {

        nav('/addscreens', { state: x })
    }

    useEffect(() => {
        TheatreLists();
    }, []);
    const TheatreLists = async () => {
        const token = localStorage.getItem('admintoken');

        axios({
            'method': 'GET',
            'url': common_url+'theatre/getAdminOwnTheatreList',
            'headers': {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
        })
            .then((response) => {
                console.log(response.data.message, 'amma');
                setTheatreList(response.data.message)
            })
            .then
            .catch((error) => {
                alert(error)
            }
            )
    }
    return (
        <div>
            <Header />
            <main class="main">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="main__title">
                                <h2>Movie Lists</h2>

                                <span class="main__title-stat">3,702 Total</span>

                                <div class="main__title-wrap">
                                    <div class="filter" id="filter__sort">
                                        <Link to="/addmovie">
                                            <button class="form__btn" type="button"  >ADD Movies</button>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="main__table-wrap">
                                <table class="main__table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>BASIC INFO</th>
                                            <th>THEATRE NAME</th>
                                            <th>THEATRE ADDRESS</th>
                                            <th>LOCATION</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {theatreList.map(x =>
                                            <tr>
                                                <td>
                                                    <div class="main__table-text">23</div>
                                                </td>
                                                <td>
                                                    <div class="main__user">
                                                        <div class="main__avatar">
                                                            <img src={font} alt="" />
                                                        </div>
                                                        <div class="main__meta">
                                                            <h3>{x.name}</h3>
                                                            <span>{x.email}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="main__table-text">{x.theatreName}</div>
                                                </td>
                                                <td>
                                                    <div class="main__table-text">{x.theatreAddress}</div>
                                                </td>
                                                <td>
                                                    <div class="main__table-text">{x.location}</div>
                                                </td>

                                                <td>
                                                    <div class="main__table-btns">

                                                        <a href="edit-user.html" class="main__table-btn main__table-btn--edit">
                                                            <i class="icon ion-ios-create"></i>
                                                        </a>
                                                        <a href="#modal-delete" class="main__table-btn main__table-btn--delete open-modal">
                                                            <i class="icon ion-ios-trash"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button class="form__btn" type="button" onClick={(e) => AddScreens(e, x)} >ADD SCREENS</button>
                                                </td>
                                            </tr>
                                        )}


                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="paginator-wrap">
                                <span>10 from 3 702</span>

                                <ul class="paginator">
                                    <li class="paginator__item paginator__item--prev">
                                        <a href="#"><i class="icon ion-ios-arrow-back"></i></a>
                                    </li>
                                    <li class="paginator__item"><a href="#">1</a></li>
                                    <li class="paginator__item paginator__item--active"><a href="#">2</a></li>
                                    <li class="paginator__item"><a href="#">3</a></li>
                                    <li class="paginator__item"><a href="#">4</a></li>
                                    <li class="paginator__item paginator__item--next">
                                        <a href="#"><i class="icon ion-ios-arrow-forward"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default MovieList