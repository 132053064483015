import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddItem from "./Components/AddItem/AddItem";
import Catelog from "./Components/catelog/catelog";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Users from "./Components/users/Users";
import SignIn from './Components/SignIn/signIn';
import EditUser from "./Components/EditUser/EditUser";
import { AuthChild } from "./Components/auth";
import ApprovedTheatreList from './Components/ApprovedTheatreList/ApprovedTheatreList';
import GetAdminRequest from "./Components/GetAdminRequest/GetAdminRequest";
import AdminHome from "./Components/AdminHome/AdminHome";
import AdminLogin from "./Components/SignIn/AdminLogin";
import TheatreList from "./Components/AdminPages/TheatreList/TheatreList";
import AddScreens from "./Components/AdminPages/Screens/AddScreens";
import MovieList from "./Components/Movie/MovieList";
import AddMovie from "./Components/Movie/AddMovie";

AuthChild.isAuthenticated()

function App() {
  return (
    <>
      {AuthChild.isLogged ?
        <>
          {AuthChild.isRole ?
            <BrowserRouter>
              <Routes>
                <Route
                  exact path="/"
                  element={<AdminHome />}
                />
                 <Route
                  exact path="/theatrelist"
                  element={<TheatreList />}
                />
                 <Route
                  exact path="/addscreens"
                  element={<AddScreens />}
                />
                  <Route
                  exact path="/movielist"
                  element={<MovieList />}
                />
                 <Route
                  exact path="/addmovie"
                  element={<AddMovie />}
                />
              </Routes>
            </BrowserRouter>
            :
            <BrowserRouter>

              <Routes>
                <Route exact path="/" element={<Home />} />
              </Routes>
              <Routes>
                <Route exact path="/catelog" element={<Catelog />} />
              </Routes>
              <Routes>
                <Route exact path="/users" element={<Users />} />
              </Routes>
              <Routes>
                <Route exact path="/additem" element={<AddItem />} />
              </Routes>
              <Routes>
                <Route exact path="/edituser" element={<EditUser />} />
              </Routes>
              <Routes>
                <Route exact path="/approvedTheatre" element={<ApprovedTheatreList />} />
              </Routes>
              <Routes>
                <Route exact path="/getadminrequest" element={<GetAdminRequest />} />
              </Routes>
            </BrowserRouter>
          }
        </>
        :
        <>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<SignIn />} />
            </Routes>
            <Routes>
              <Route exact path="/adminLogin" element={<AdminLogin />} />
            </Routes>
          </BrowserRouter>
        </>
      }
    </>
  );
}

export default App;
